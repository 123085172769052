import React, {useState, useEffect} from 'react';
import Icon from "../../../components/Icon";
import Calendar from 'react-calendar';
import {formatDate} from "date-fns/format";
import {ru} from "date-fns/locale";
import {apiServiceDateList} from "../../../api/appointmentApi";
import {format} from 'date-fns';

function StepCorrectionDate({toNextStep}) {
  const [list, setList] = useState([])
  const [date, setDate] = useState();

  useEffect(() => {
    apiServiceDateList().then((res) => setList(res))
  }, [])

  const handleSelect = () => {
    toNextStep(date)
  }

  return (
    <div className="container page-appointment__step-container">
      <div className="panel panel--top">
        <div className="page-appointment__top">
          <p className="page-appointment__title">Дата коррекции</p>
          <p className="page-appointment__description">Время коррекции будет согласованно после записи</p>
          <p className="page-appointment__note">
            <Icon name="info" className="page-appointment__note-icon"/>
            Окончательное решение о методе коррекции принимается после диагностики
          </p>
        </div>
      </div>
      <div className="panel">
        <Calendar
          onChange={setDate}
          value={date}
          showNeighboringMonth={false}
          view={'month'}
          next2Label={null}
          prev2Label={null}
          prevLabel={<Icon name="angle-left" className="react-calendar__nav-icon"/>}
          nextLabel={<Icon name="angle-right" className="react-calendar__nav-icon"/>}
          formatMonthYear={(locale, date) => formatDate(date, 'LLLL yyyy', {locale: ru})}
          minDate={new Date()}
          tileDisabled={({activeStartDate, date, view}) => {
            const formattedDate = format(new Date(date), 'dd.MM.yyyy');
            return !list.includes(formattedDate)
          }}
        />
      </div>
      <button
        className="button page-appointment__bottom-button"
        type="button"
        disabled={!date}
        onClick={handleSelect}
      >
        Продолжить
      </button>
    </div>
  );
}

export default StepCorrectionDate;