import "./appointment-table.scss"
import Icon from "../../../../Icon";
import {useState} from "react";
import cn from "classnames";


const AppointmentTable = () => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <table className={cn('appointment-table', {'appointment-table--expanded':isExpanded})}>
      <tbody>
      <tr>
        <td>Диагностика</td>
        <td>2 500 ₽</td>
      </tr>
      <tr>
        <td>
          Операция
          <p className="appointment-table__note">Оплачивается после диагностики</p>
        </td>
        <td>17 500 ₽</td>
      </tr>
      <tr className="appointment-table__before-hidden-row">
        <td>Скидка
          <button
            className="appointment-table__expand-btn"
            onClick={() => setIsExpanded(!isExpanded)}
          >
          <Icon name="drop-down" className="appointment-table__chevron"/>
        </button>
        </td>
        <td className="appointment-table__text-red">−500 ₽</td>
      </tr>
      <tr className="appointment-table__hidden-row">
        <td>Акция</td>
        <td>−300 ₽</td>
      </tr>
      <tr className="appointment-table__hidden-row">
        <td>Купон</td>
        <td>−200 ₽</td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td>Итого</td>
        <td>2 000 ₽</td>
      </tr>
      </tfoot>
    </table>
  )
}

export default AppointmentTable