export const preventScroll = (isOpen) => {
  if (isOpen) {
    const scrollY = window.scrollY;
    document.body.style.top = `-${scrollY}px`;
    document.body.classList.add("prevent-scroll");
  } else {
    const scrollY = Math.abs(parseInt(document.body.style.top || "0", 10));
    document.body.classList.remove("prevent-scroll");
    document.body.style.top = "";
    window.scrollTo(0, scrollY);
  }
}