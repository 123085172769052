import PropTypes from 'prop-types';
import cn from "classnames";

const PaymentStatus = ({isPaid}) => {
  return (
    <div className={cn('payment-status', {'payment-status--green': isPaid}, {'payment-status--red': !isPaid})}>
      {isPaid ? 'Оплачено' : 'Не оплачено'}
    </div>
  )
}

PaymentStatus.propTypes = {
  isPaid: PropTypes.bool.isRequired
}
export default PaymentStatus