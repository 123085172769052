import PaymentStatus from "./PaymentStatus";
import PropTypes from "prop-types";
import Icon from "./Icon";
import ModalStepDetail from "./modals/ModalStepDetail";
import {useState} from "react";
import {useAppointmentStore} from "../store/appointmentStore";
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import ModalPrepare from "./modals/ModalPrepare";

const Stage = ({name, payment, clinic, date, method}) => {
  const [result] = useAppointmentStore((state) => [state.result])

  const [showStepDetailModal, setStepDetailModal] = useState(false)
  const [showPrepareModal, setShowPrepareModal] = useState(false)

  return (
    <>
      <div className="stage">
        <div className="stage__top">
          <p className="stage__name">{name}</p>
          <PaymentStatus isPaid={payment.isPaid}/>
        </div>
        <div className="stage__content">
          <ul className="stage__content-list">
            <li className="stage__content-elem">
              <Icon name="buildings" className="stage__content-icon"/>
              <div className="stage__content-box">
                <p className="stage__content-city">{clinic.city}</p>
                <p className="stage__content-addr">{result.clinic.addr}</p>
                <button type="button" className="stage__content-map-link">Показать на карте</button>
              </div>
            </li>
            <li className="stage__content-elem">
              <Icon name="calendar" className="stage__content-icon"/>
              <div className="stage__content-box">
                <p className="stage__content-date">{format(result.diagnosticsDate, 'd MMMM в HH:mm', { locale: ru })}</p>
              </div>
            </li>
            <li className="stage__content-elem">
              <Icon name="laser" className="stage__content-icon"/>
              <div className="stage__content-box">
                <p className="stage__content-method">{result.service.name}</p>
              </div>
            </li>
          </ul>
        </div>
        <div className="stage__bottom">
          {!payment.isPaid && (
            <button type="button" className="button button_h-52">Оплатить</button>
          )}
          <button type="button" className="button button_h-52 button_color-2" onClick={() => setStepDetailModal(true)}>Подробнее</button>
        </div>
      </div>

      <ModalStepDetail
        active={showStepDetailModal}
        onClose={() => setStepDetailModal(false)}
        setShowPrepareModal={setShowPrepareModal}
      />

      <ModalPrepare
        active={showPrepareModal}
        onClose={() => setShowPrepareModal(false)}
        showAgreement={false}
      />
    </>
  )
}

Stage.propTypes = {
  name: PropTypes.string.isRequired,
  isPaid: PropTypes.bool
}

export default Stage