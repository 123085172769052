import {motion} from "framer-motion";
import Icon from "../Icon";
import {useState} from "react";

const ModalPrepare = ({active, onClose, showAgreement}) => {
  const [canClose, setCanClose] = useState(!showAgreement)
  const show = {
    opacity: 1,
    display: "block",
  };

  const hide = {
    opacity: 0,
    transitionEnd: {
      display: "none"
    }
  };

  return (
    <motion.div
      animate={active ? show : hide}
      className={`modal modal_type-3 modal-prepare modal-w-inputs ${active ? 'modal--show' : ''}`}
    >
      <div className="modal__inner">
        <div className="modal__wrap">
          <div onClick={onClose} className="modal__close modal-close">
            <Icon name="close"/>
          </div>
          <div className="modal__content">
            <h2 className="h2 modal-prepare__title">Подготовка к коррекции</h2>
            <p className="modal-prepare__text">За 7 дней до диагностики и коррекции исключить использование мягких
              дневных линз, а жестких и ночных — за 3 месяца. Очками пользоваться можно</p>
            <div className="modal-prepare__bottom-box">
              {showAgreement && (
                <div className="checkbox modal-prepare__checkbox">
                  <input
                    onChange={() => setCanClose(!canClose)}
                    checked={canClose}
                    className="checkbox__input"
                    type="checkbox"
                    id="agreement"
                    name="agreement"
                  />
                  <div className="checkbox__check">
                    <Icon name={'check'}/>
                  </div>
                  <label className="checkbox__label" htmlFor="agreement">Согласен с требованиями</label>
                </div>
              )}
              <button
                type="button"
                className="button button_h-52"
                disabled={!canClose}
                onClick={onClose}
              >
                Продолжить
              </button>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}
export default ModalPrepare