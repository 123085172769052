import React, {useState, useEffect} from 'react';
import {apiGetClinics} from "../../../api/appointmentApi";
import Clinic from "../../Clinic/Clinic";
import SelectCity from "../../SelectCity";

function StepClinicSelect({toNextStep}) {
  const [list, setList] = useState([])
  const [selectedCity, setSelectedCity] = useState(null)

  useEffect(() => {
    apiGetClinics(1).then((res) => setList(res))
  }, [])

  useEffect(() => {
    if (selectedCity) {
      apiGetClinics(selectedCity).then((res) => setList(res))
    }
  }, [selectedCity])

  return (
    <div className="container">
      <div className="panel panel--top">
        <div className="page-appointment__top">
          <p className="page-appointment__title">Клиника</p>
          <SelectCity
            placeholder="Город"
            onSelect={setSelectedCity}
          />
        </div>
      </div>
      {list && list.length > 0 && list.map((item) => (
        <Clinic {...item} key={item.index} handleSelect={toNextStep}/>
      ))}
    </div>
  );
}

export default StepClinicSelect;