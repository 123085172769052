import {useNavigate} from "react-router-dom";
import "./page-appointment.scss"
import Knowledge from "../../components/Knowledge/Knowledge";
import {useAppointmentStore} from "../../store/appointmentStore";
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import ModalPrepare from "../../components/modals/ModalPrepare";
import {useState} from "react";

const PageAppointmentResult = () => {
  const [result] = useAppointmentStore((state) => [state.result])
  const [showPrepareModal, setShowPrepareModal] = useState(false)
  const navigate = useNavigate();

  return (
    <div className="page-appointment page-appointment--result">
      <div className="container page-appointment__step-container">
        <div className="panel">
          <img src="/assets/images/success.svg" className="page-appointment__result-img" alt=""/>
          <div className="page-appointment__top">
            <p className="page-appointment__title">
              Поздравляем!
              <br/>
              Вы записаны на коррекцию
            </p>
          </div>
          <div className="page-appointment__data">
            <div className="page-appointment__data-row">
              <p className="page-appointment__data-elem">Клиника</p>
              <p className="page-appointment__data-elem">{result.clinic.name}</p>
            </div>
            <div className="page-appointment__data-row">
              <p className="page-appointment__data-elem">Метод коррекции</p>
              <p className="page-appointment__data-elem">{result.service.name}</p>
            </div>
            <div className="page-appointment__data-row">
              <p className="page-appointment__data-elem">Стоимость коррекции</p>
              <p className="page-appointment__data-elem">{result.service.price} ₽</p>
            </div>
            <div className="page-appointment__data-row">
              <p className="page-appointment__data-elem">Дата коррекции</p>
              <p className="page-appointment__data-elem">{format(result.correctionDate, 'd MMMM', { locale: ru })}</p>
            </div>
            <div className="page-appointment__data-row">
              <p className="page-appointment__data-elem">Дата диагностики</p>
              <p className="page-appointment__data-elem">{format(result.diagnosticsDate, 'd MMMM в HH:mm', { locale: ru })}</p>
            </div>
            <div className="page-appointment__data-row">
              <p className="page-appointment__data-elem">Стоимость диагностики</p>
              <p className="page-appointment__data-elem">2 500 ₽</p>
            </div>
          </div>
        </div>
        <Knowledge setShowPrepareModal={setShowPrepareModal}/>
        <ModalPrepare
          active={showPrepareModal}
          onClose={() => setShowPrepareModal(false)}
          showAgreement={false}
        />
        <button
          className="button page-appointment__bottom-button"
          type="button"
          onClick={() => navigate('/main-auth')}
        >
          Вернуться на главную
        </button>
      </div>
    </div>
  )
}
export default PageAppointmentResult