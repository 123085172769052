import Icon from "../../../Icon";
import PropTypes from "prop-types";
import "./header-appointment.scss"

const HeaderAppointment = ({onBackClick, title, subtitle, showData}) => {
  return (
    <header className="header-appointment">
      <div className="container">
        <div className="header-appointment__content">
          <button className="header-appointment__back" onClick={onBackClick}>
            <Icon name="angle-left" className="header-appointment__back-icon"/>
          </button>
          {showData && (
            <div className="header-appointment__title-box">
              <p className="header-appointment__title">{title}</p>
              {subtitle && (
                <p className="header-appointment__subtitle">{subtitle}</p>
              )}
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

HeaderAppointment.propTypes = {
  onBackClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showData: PropTypes.bool.isRequired,
}
export default HeaderAppointment