import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AnimatePresence, motion} from 'framer-motion';
import StepWrapper from "../../components/StepWrapper";
import StepClinicSelect from "../../components/steps/appointment/StepClinicSelect";
import StepServiceSelect from "../../components/steps/appointment/StepServiceSelect";
import StepCorrectionDate from "../../components/steps/appointment/StepCorrectionDate";
import StepDiagnosticDate from "../../components/steps/appointment/StepDiagnosticDate";
import StepMakeAppointment from "../../components/steps/appointment/StepMakeAppointment";
import Header from "../../components/Header";
import HeaderAppointment from "../../components/steps/appointment/HeaderAppointment/HeaderAppointment";
import "./page-appointment.scss"
import {useUserStore} from "../../store/user-store";
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import {useAppointmentStore} from "../../store/appointmentStore";

const PageAppointment = () => {
  const [result, setResult] = useAppointmentStore((state) => [state.result, state.setResult])
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [showHeaderData, setShowHeaderData] = useState(true)

  useEffect(() => {
    window.scrollTo(0,0)
  }, [step])

  const onBackClick = () => {
    switch (step) {
      case 1: {
        navigate('/main-auth')
        break
      }
      case 2: {
        setResult({clinic: {id: '', name: '', addr: ''}})
        setStep(1)
        break
      }
      case 3: {
        setResult({service: {id: '', name: '', price: ''}})
        setStep(2)
        break
      }
      case 4: {
        setResult({correctionDate: ''})
        setStep(3)
        break
      }
      case 5: {
        setResult({diagnosticsDate: ''})
        setShowHeaderData(true)
        setStep(4)
        break
      }
    }
  }

  return (
    <div className="page-appointment">
      <div className="auth__progressbar progressbar">
        <div className="progressbar__progress" style={{width: `${step * 20}%`}}></div>
      </div>
      <HeaderAppointment
        onBackClick={onBackClick}
        title={result.clinic.name}
        subtitle={`${result.service.name}${result.correctionDate ? ` · ${format(result.correctionDate, 'd MMMM', { locale: ru })}`: ''}`}
        showData={showHeaderData}
      />

      <AnimatePresence mode="wait" initial="false">

        {/* ------------------ Step 1  ------------------ */}
        {step === 1 && (
          <StepWrapper stepKey="step1">
            <StepClinicSelect
              toNextStep={(clinic) => {
                setResult({clinic: clinic})
                setStep(2)
              }}
            />
          </StepWrapper>
        )}

        {/* ------------------ Step 2 ------------------ */}
        {step === 2 && (
          <StepWrapper stepKey="step2">
            <StepServiceSelect
              toNextStep={(service) => {
                setResult({service: service})
                setStep(3)
              }}
            />
          </StepWrapper>
        )}

        {/* ------------------ Step 3 ------------------ */}
        {step === 3 && (
          <StepWrapper stepKey="step3">
            <StepCorrectionDate
              toNextStep={(date) => {
                setResult({correctionDate: date})
                setStep(4)
              }}
            />
          </StepWrapper>
        )}

        {/* ------------------ Step 4 ------------------ */}
        {step === 4 && (
          <StepWrapper stepKey="step4">
            <StepDiagnosticDate
              toNextStep={(date) => {
                //setDateDiagnostics(date)
                setResult({diagnosticsDate: date})
                setShowHeaderData(false)
                setStep(5)
              }}
            />
          </StepWrapper>
        )}

        {/* ------------------ Step 5 ------------------ */}
        {step === 5 && (
          <StepWrapper stepKey="step5">
            <StepMakeAppointment
              toPrevStep={() => setStep(4)}
              toNextStep={() => setStep(6)}
            />
          </StepWrapper>
        )}

      </AnimatePresence>
    </div>
  )
}
export default PageAppointment