import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AnimatePresence, motion} from 'framer-motion';

import LayoutAuth from "../components/layouts/LayoutAuth";
import ScreenPhone from "../components/screens/ScreenPhone";
import ScreenCode from "../components/screens/ScreenCode";
import ScreenData from "../components/screens/ScreenData";
import ScreenPin from "../components/screens/ScreenPin";
import StepWrapper from "../components/StepWrapper";
import {apiGetUserProfile} from "../api/userApi";
import {useUserStore} from "../store/user-store";
import ScreenCity from "../components/screens/ScreenCity";

const PageRegister = () => {
  const [firstName, secondName, lastName, birthDate, email, city] = useUserStore((state) => [state.firstName, state.secondName, state.lastName, state.birthDate, state.email, state.city])
  const navigate = useNavigate();
  const  [showUserProfileForm, setShowUserProfileForm]  = useState(true);
  const [step, setStep] = useState(1);

  useEffect(() => {
    window.scrollTo(0,0)
  }, [step])

  useEffect(() => {
    if (step === 3) {
      apiGetUserProfile().then((res) => {
        if (res.data.firstName !== null && res.data.secondName !== null && res.data.birthDate !== null && res.data.email !== null) {
          setStep(4)
        }
      })
    }
  }, [step]);

  return (
    <LayoutAuth>
      <div className="auth__progressbar progressbar">
        <div className="progressbar__progress" style={{width: `${step * 16.7}%`}}></div>
      </div>
      <AnimatePresence mode="wait" initial="false">

        {/* ------------------ Step 1  ------------------ */}
        {step === 1 && (
          <StepWrapper stepKey="step1">
            <ScreenPhone
              mode={'verifyStart'}
              toPrevStep={() => navigate('/main')}
              toNextStep={() => setStep(2)}
            />
          </StepWrapper>
        )}

        {/* ------------------ Step 2 ------------------ */}
        {step === 2 && (
          <StepWrapper stepKey="step2">
            <ScreenCode
              toPrevStep={() => setStep(1)}
              toNextStep={() => setStep(3)}
            />
          </StepWrapper>
        )}

        {/* ------------------ Step 3 ------------------ */}
        {step === 3 && (
          <StepWrapper stepKey="step3">
            <ScreenData
              toPrevStep={() => setStep(2)}
              toNextStep={() => setStep(4)}
            />
          </StepWrapper>
        )}

        {/* ------------------ Step 4 ------------------ */}
        {step === 4 && (
          <StepWrapper stepKey="step4">
            <ScreenCity
              toPrevStep={() => setStep(3)}
              toNextStep={() => setStep(5)}
            />
          </StepWrapper>
        )}

        {/* ------------------ Step 5 ------------------ */}
        {step === 5 && (
          <StepWrapper stepKey="step5">
            <ScreenPin
              mode="create"
              toPrevStep={() => setStep(4)}
              toNextStep={() => setStep(6)}
            />
          </StepWrapper>
        )}

        {/* ------------------ Step 6 ------------------ */}
        {step === 6 && (
          <StepWrapper stepKey="step6">
            <ScreenPin
              mode="repeat"
              toPrevStep={() => setStep(5)}
              toNextStep={() => navigate('/main-auth')}
            />
          </StepWrapper>
        )}
      </AnimatePresence>
    </LayoutAuth>
  )
}
export default PageRegister