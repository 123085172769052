import "./page-payment-result.scss"
import {useNavigate, useSearchParams} from "react-router-dom";

const PagePaymentResult = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const backTo = searchParams.get("backTo")

  const handleClick = () => {
    if (backTo && backTo === "result") {
      navigate('/appointment-result')
    } else {
      navigate('/main-auth')
    }
  }

  return (
    <div className="page-payment-result container">
      <div className="page-payment-result__content">
        <img src="/assets/images/success.svg" alt="" className="page-payment-result__img"/>
        <p className="page-payment-result__title">Запись оплачена</p>
      </div>
      <button className="page-payment-result__btn button" onClick={handleClick}>
        {backTo && backTo === "result" && (
          <>Продолжить</>
        )}
        {!backTo && !backTo === "result" && (
          <>Вернуться на главную</>
        )}
      </button>
    </div>
  )
}

export default PagePaymentResult;