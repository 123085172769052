import {motion} from "framer-motion";
import Icon from "../Icon";
import React from "react";
import Knowledge from "../Knowledge/Knowledge";
import AppointmentTable from "../steps/appointment/components/AppointmentTable/AppointmentTable";
import PaymentStatus from "../PaymentStatus";
import {preventScroll} from "../../utils/preventScroll";

const ModalStepDetail = ({active, onClose, setShowPrepareModal}) => {
  const show = {
    opacity: 1,
    display: "block",
  };

  const hide = {
    opacity: 0,
    transitionEnd: {
      display: "none"
    }
  };
  return (
    <motion.div
      animate={active ? show : hide}
      className={`modal modal_type-3 modal--center-title modal-step-detail  modal--no-bg modal-ste1ps modal-w-inputs ${active ? 'modal--show' : ''}`}
      onAnimationComplete={() => preventScroll(active)}
    >
      <div className="modal__inner">
        <div className="modal__wrap">
          <div className="modal__content">

            <div className="panel">
              <h2 className="h2 modal__title modal-steps__title">Диагностика</h2>

              <div onClick={onClose} className="modal__close modal-close">
                <Icon name="close"/>
              </div>

              <div className="modal-step-detail__panel-header">Детали записи</div>
              <div className="modal-step-detail__data-group">
                <p className="modal-step-detail__data-label">ФИО</p>
                <p className="modal-step-detail__data-value">Васильев Владимир Серафимович</p>
              </div>
              <div className="modal-step-detail__data-group">
                <p className="modal-step-detail__data-label">Клиника</p>
                <p className="modal-step-detail__data-value">«Кузляр» ул. Чистопольская, 16/15, Казань, Республика
                  Татарстан</p>
              </div>
              <div className="modal-step-detail__data-group">
                <p className="modal-step-detail__data-label">Метод коррекции</p>
                <p className="modal-step-detail__data-value">Lasik</p>
              </div>
              <div className="modal-step-detail__data-group">
                <p className="modal-step-detail__data-label">Дата диагностики</p>
                <p className="modal-step-detail__data-value">25 марта в 12:20</p>
              </div>
            </div>
            <div className="panel">
              <div className="modal-step-detail__panel-header">
                Оплата
                <PaymentStatus isPaid={true}/>
              </div>
              <AppointmentTable/>
            </div>
            <Knowledge setShowPrepareModal={setShowPrepareModal}/>
          </div>
        </div>
      </div>
    </motion.div>
  )
}
export default ModalStepDetail