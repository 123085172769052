import { create } from 'zustand';
import {
  apiCurrentStage,
  apiGetCityList,
  apiGetStages,
  apiGetStagesList,
  apiSetSelectedCityId
} from "../api/appointmentApi";

export const useAppointmentStore = create((set, get) => ({
  haveAppointment: false, // TODO: костыль
  setHaveAppointment:  () => set({haveAppointment: true}),
  cityList: [],
  stagesList: [],
  stages:[],
  currentStage:{},
  result: {
    clinic: {id: '', name: '', addr: ''},
    service: {id: '', name: '', price: ''},
    correctionDate: '',
    diagnosticsDate: '',
  },
  setResult: (data) => {
    set({result: {...get().result, ...data}})
    console.log(get().result)
  },
  setSelectedCity: async (cityId) => {
    const response = await apiSetSelectedCityId(cityId)
    const result = await response
    set({selectedCityId: cityId})
  },
  getCityList: async () => {
    const response = await apiGetCityList()
    const result = await response
    set({cityList: result})
  },
  getCurrentStage: async () => {
    const response = await apiCurrentStage()
    const result = await response
    set({currentStage: result})
  },
  getStages: async () => {
    const response = await apiGetStages()
    const result = await response
    set({stages: result})
  },
}))