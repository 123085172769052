import Header from "@components/Header";
import Footer from "@components/Footer";
import Icon from "@components/Icon";
import Stories from "@components/Stories";
import {Link} from "react-router-dom";
import ReviewList from "../components/ReviewList";
import {useAdvantageStore} from "../store/advantage-store";
import {useUserStore} from "../store/user-store";
import React, {useEffect, useState} from "react";
import Advantage from "../components/Advantage";
import ModalQuestions from "../components/modals/ModalQuestions";
import ModalReviews from "../components/modals/ModalReviews";
import ModalCallRequest from "../components/modals/ModalCallRequest";
import ModalSubscribe from "../components/modals/ModalSubscribe";
import Stages from "../components/Stages";
import {useAppointmentStore} from "../store/appointmentStore";

const PageMain = () => {
  const [firstName, avatar] = useUserStore((state) => [state.firstName, state.avatar]);
  const [advantages, getAdvantages] = useAdvantageStore((state) => [state.advantages, state.getAdvantages]);
  const [advantagesList, setAdvantagesList] = useState(Array(3).fill({}));
  const [haveAppointment] = useAppointmentStore((state) => [state.haveAppointment])
  const [showStages, setShowStages] = useState(haveAppointment);

  useEffect(() => {
    getAdvantages(true)
    if (!localStorage.getItem('animationShown')) {
      //document.body.classList.add("mainpageAnim");
      localStorage.setItem('animationShown', 'true')
    }
  }, [getAdvantages]);

  useEffect(() => {
    if (advantages.length > 0) {
      setAdvantagesList(advantages);
    }
  }, [advantages]);

  const [isModalQuestionsActive, setIsModalQuestionsActive] = useState(false);
  const [isModalReviewsActive, setIsModalReviewsActive] = useState(false);
  const [isModalCallRequestActive, setIsModalCallRequestActive] = useState(false);

  const toggleModalQuestions = () => {
    setIsModalQuestionsActive(!isModalQuestionsActive);
  };

  const toggleModalReviews = () => {
    setIsModalReviewsActive(!isModalReviewsActive);
  };

  const toggleModalCallRequest = () => {
    setIsModalCallRequestActive(!isModalCallRequestActive);
  };

  return (
    <div className="_mainpageAnim">
      <div className="masked">
        <Header isAuth={true}/>
        <div className="main authorized bg_2">
          <div className="sections">
            <section className="section section_top container">
              <div className="greeting">
                <h1 className="greeting__text">Здравствуйте, <span className="greeting__username">{firstName}</span>
                </h1>
                <Link className="greeting__avatar" to="/settings">
                  <img src={avatar.length ? avatar : "./assets/images/avatar.svg"}
                       alt="avatar"/>
                </Link>
              </div>
              <Stories/>
            </section>
            {!showStages && (
              <section className="section container">
                <h2 className="h2 mt_24 mb_16">Записаться на коррекцию</h2>
                <ul className="advantages">
                  {
                    advantagesList.map((advantage, index) => (<Advantage key={index.toString()} {...advantage}/>))
                  }
                </ul>
                <button
                  className="button mb_24"
                  onClick={() => setShowStages(true)}
                >
                  Записаться на коррекцию
                </button>
              </section>
            )}

            {showStages && (<Stages/>)}

            <section className="section container">
              <ReviewList/>
              <button className="button button_color-2 mb_24" data-popup="reviews" onClick={toggleModalReviews}>Все
                отзывы
              </button>
            </section>
            <section className="section container">
              <div className="user-actions">
                <div className="user-action" data-popup="questions" onClick={toggleModalQuestions}>
                  <Icon name="faq"/>
                  <span className="user-action__text">Частые вопросы</span>
                  <Icon name="angle-right" className="user-action__icon-angle"/>
                </div>
              </div>
            </section>
            <section className="section container">
              <div className="user-actions">
                <div className="user-action">
                  <Icon name="call-out"/>
                  <div className="user-action__text"><a href="tel:88001009876">8 800 100 98 76 <br/></a><span
                    className="user-action__details">05:00 – 21:00 по МСК. Без выходных</span></div>
                </div>
                <div onClick={toggleModalCallRequest} className="user-action" data-popup="callRequest">
                  <Icon name="call-in"/>
                  <span className="user-action__text">Заказать звонок</span>
                  <Icon name="angle-right" className="user-action__icon-angle"/>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Footer/>
      </div>
      <ModalQuestions active={isModalQuestionsActive} onClose={toggleModalQuestions}/>
      <ModalReviews active={isModalReviewsActive} onClose={toggleModalReviews}/>
      <ModalCallRequest active={isModalCallRequestActive} onClose={toggleModalCallRequest}/>
      <svg className="mask-svg" width="0" height="0" fill="#0171DD" xmlns="http://www.w3.org/2000/svg">
        <clipPath id="mask">
          <path
            d="M54.5436 25.887V28.5749C49.0016 28.5761 44.6189 28.6889 41.1639 29.2294C37.6899 29.7729 35.1429 30.7504 33.2957 32.4916C31.4481 34.2332 30.312 36.728 29.6369 40.2821C28.9652 43.8177 28.7479 48.4112 28.7458 54.3727H26.0579C26.0558 48.4112 25.8385 43.8177 25.1669 40.2821C24.4917 36.728 23.3556 34.2332 21.508 32.4916C19.6608 30.7504 17.1138 29.7729 13.6398 29.2294C10.1849 28.6889 5.80213 28.5761 0.260092 28.5749V25.887C5.8578 25.886 10.2685 25.7889 13.7339 25.2681C17.2182 24.7444 19.7586 23.7907 21.5921 22.065C23.426 20.3389 24.5409 17.8515 25.1985 14.2858C25.8527 10.7385 26.056 6.11344 26.0579 0.0891934H28.7458C28.7478 6.11294 28.9509 10.7377 29.6049 14.285C30.2623 17.8505 31.3771 20.338 33.2108 22.0642C35.0442 23.7901 37.5846 24.7439 41.069 25.2678C44.5344 25.7888 48.9454 25.886 54.5436 25.887Z"
            fill="white" stroke="white" strokeWidth="0.178387"></path>
        </clipPath>
      </svg>
      <ModalSubscribe/>
    </div>
  );
}

export default PageMain;
