import PropTypes from "prop-types";

const Radio = ({label, name, value, onCheck, checked}) => {
  return (
    <label className="radio">
      <input
        className="radio__input"
        type="radio"
        name={name}
        value={value}
        onChange={() => onCheck(value)}
        defaultChecked={checked}
      />
      <span className="radio__text">{label}</span>
    </label>
  )
}

Radio.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onCheck: PropTypes.func.isRequired,
}

export default Radio