import {useForm} from "react-hook-form";

import Icon from "../Icon";
import {useEffect, useState} from "react";
import {useAppointmentStore} from "../../store/appointmentStore";
import Radio from "../Radio";
import {useUserStore} from "../../store/user-store";

const ScreenCity = ({mode, toPrevStep, toNextStep}) => {
  const [cityList] = useAppointmentStore((state) => [state.cityList])
  const [submitPersonalInfo] = useUserStore((state) => [state.submitPersonalInfo])
  const { handleSubmit, formState} = useForm();
  const [cityId, setCityId] = useState();

  const onSubmit = (cityId) => {
    submitPersonalInfo({clinicCityId: cityId})
    toNextStep()
  }

  return (
    <form className="container auth-step" onSubmit={ () => onSubmit(cityId)}>
      <button className="auth__back" onClick={toPrevStep} type="button">
        <Icon name="angle-left"/>
      </button>
      <div className="content-block content-block--screen-city">
        <div className="auth__title">
          <h1 className="h1">Выберите город для проведения коррекции</h1>
          <p className="auth__city-text">Можно будет изменить в настройках</p>
        </div>
        <div className="auth__city-list">
          {cityList.map((item) => (
            <Radio
              onCheck={setCityId}
              name="city"
              label={item.text}
              value={item.id}
            />
          ))}
        </div>
      </div>
      <div className="bottom-block">
        <button className="button" type="submit" disabled={formState.isSubmitting || !cityId}>Продолжить</button>
      </div>
    </form>
  )
}
export default ScreenCity