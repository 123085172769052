import PaymentStatus from "./PaymentStatus";
import {useEffect, useState} from "react";
import {useAppointmentStore} from "../store/appointmentStore";
import Stage from "./Stage";
import StageInitial from "./StageInitial";
import cn from "classnames";
import ModalPrepare from "./modals/ModalPrepare";
import ModalSteps from "./modals/ModalSteps";
import ModalStepDetail from "./modals/ModalStepDetail";
import {apiCurrentStage} from "../api/appointmentApi";

const Stages = () => {
  const [haveAppointment, currentStage, getCurrentStage, stages, getStages] = useAppointmentStore((state) => [state.haveAppointment, state.currentStage, state.getCurrentStage, state.stages, state.getStages])
  const [stage, setStage] = useState(1)
  const [showStagesModal, setShowStagesModal] = useState(false)

  useEffect(() => {
    getStages()
    getCurrentStage()
  }, [])

  useEffect(() => {
    if(haveAppointment) {
      setStage(2)
    }
  }, [haveAppointment])

  return (
    <>
      {stages.length > 0 && (
        <div className="stages section container">
          <div className="stages__progress">
            {stages.map((_, index) =>
              <div className={cn('stages__progress-step', {'stages__progress-step--filled': stage > index})} />
            )}
          </div>
          <div className="stages__progress-data">
            <div className="stages__progress-data-text">Этап {stage} из {stages.length}</div>
            <button
              className="stages__progress-data-all"
              onClick={() => setShowStagesModal(true)}
            >
              Все этапы
            </button>
          </div>
          {!haveAppointment && (<StageInitial/>)}
          {haveAppointment && (<Stage {...currentStage} />)}
        </div>
      )}
      <ModalSteps
        active={showStagesModal}
        onClose={() => setShowStagesModal(false)}
      />
    </>
  )
}

export default Stages