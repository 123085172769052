import PaymentStatus from "./PaymentStatus";
import PropTypes from "prop-types";
import Select from "./Select";
import React, {useEffect, useState} from "react";
import {useAppointmentStore} from "../store/appointmentStore";
import {apiGetClinics} from "../api/appointmentApi";
import {useNavigate} from "react-router-dom";
import SelectCity from "./SelectCity";
import ModalCity from "./modals/ModalCity";
import {useUserStore} from "../store/user-store";

const StageInitial = () => {
  const navigate = useNavigate();
  const [clinicCityId] = useUserStore((state) => [state.clinicCityId])
  const [showCityModal, setShowCityModal] = useState(false)

  return (
    <>
      <div className="stage">
        <div className="stage__top">
          <p className="stage__name">Записаться на коррекцию</p>
        </div>
        <div className="stage__content">
          <SelectCity
            placeholder="Город"
            className="stage__city-select"
            onSelect={() => {}}
          />
        </div>
        <div className="stage__bottom">
          {clinicCityId && (
            <button
              type="button"
              className="button button_h-52"
              onClick={() => navigate('/appointment')}
            >
              Выбрать клинику
            </button>
          )}
          {!clinicCityId && (
            <button
              type="button"
              className="button button_h-52"
              onClick={() => setShowCityModal(true)}
            >
              Выберите город
            </button>
          )}
        </div>
      </div>
      <ModalCity
        active={showCityModal}
        onClose={() => setShowCityModal(false)}
        showAgreement={true}
      />
    </>
  )
}

StageInitial.propTypes = {
  initialCityId: PropTypes.string.isRequired
}

export default StageInitial