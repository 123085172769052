import {motion} from "framer-motion";
import Icon from "../Icon";
import {useState} from "react";
import {useAppointmentStore} from "../../store/appointmentStore";
import {preventScroll} from "../../utils/preventScroll";

const ModalSteps = ({active, onClose}) => {
  const [stages] = useAppointmentStore((state) => [state.stages])
  const show = {
    opacity: 1,
    display: "block",
  };

  const hide = {
    opacity: 0,
    transitionEnd: {
      display: "none"
    }
  };

  return (
    <motion.div
      animate={active ? show : hide}
      className={`modal modal_type-3 modal-steps modal-w-inputs ${active ? 'modal--show' : ''}`}
      onAnimationComplete={() => preventScroll(active)}
    >
      <div className="modal__inner">
        <div className="modal__wrap">
          <div onClick={onClose} className="modal__close modal-close">
            <Icon name="close"/>
          </div>
          <div className="modal__content">
            <h2 className="h2 modal-steps__title">Все этапы</h2>
            <ul className="modal-steps__list">
              {stages.map((stage)=>(
                <li className="modal-steps__elem">
                  <div className="modal-steps__elem-icon-box">
                    {stage.isCompleted && (<Icon name="tick-16" className="modal-steps__elem-icon"/>)}
                    {!stage.isCompleted && (<>{stage.id}</>)}
                  </div>
                  <div className="modal-steps__elem-content">
                    <p className="modal-steps__elem-title">{stage.name}</p>
                    {stage.description && (
                      <p className="modal-steps__elem-text">{stage.description}</p>
                    )}
                  </div>
                </li>
              ))}
            </ul>
            <div className="modal-steps__bottom-box">
              <button
                type="button"
                className="button button_h-52"
                onClick={onClose}
              >
                Закрыть
              </button>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}
export default ModalSteps