import LayoutWithTopBlock from "@components/layouts/LayoutWithTopBlock";
import Icon from "@components/Icon";
import {useUserStore} from "../store/user-store";
import Switch from "../components/buttons/switch";
import ModalSettingsName from "../components/modals/ModalSettingsName";
import {useLayoutEffect, useState, useRef, useEffect} from "react";
import ModalSettingsBirthday from "../components/modals/ModalSettingsBirthday";
import ModalSettingsCity from "../components/modals/ModalSettingsCity";
import ModalSettingsPhone from "../components/modals/ModalSettingsPhone";
import ModalSettingsEmail from "../components/modals/ModalSettingsEmail";
import ModalSettingsPassword from "../components/modals/ModalSettingsPassword";
import {subscribePush} from "../api/pushApi";
import {apiGetUserProfile, updateUserAvatar} from "../api/userApi";
import {useSubscribe} from "../utils/hooks/useSubscribe";
import {pushPublicKey} from "../utils/consts";
import apiClient from "../api/axiosConfig";
import ModalCity from "../components/modals/ModalCity";
import {useAppointmentStore} from "../store/appointmentStore";

const PageSettings = () => {

  const user = useUserStore((state) => state);
  const [cityList] = useAppointmentStore((state) => [state.cityList]);
  const [submitPersonalInfo] = useUserStore((state) => [state.submitPersonalInfo])


  const {phone, firstName, secondName, lastName, birthDate, email, city, pin, push, avatar, clinicCityId} = user;

  const [settingNameActive, setSettingNameActive] = useState(false);
  const [settingBirthdayActive, setSettingBirthdayActive] = useState(false);
  const [settingCityActive, setSettingCityActive] = useState(false);
  const [settingPhoneActive, setSettingPhoneActive] = useState(false);
  const [settingEmailActive, setSettingEmailActive] = useState(false);
  const [settingPasswordActive, setSettingPasswordActive] = useState(false);
  const [showCityModal, setShowCityModal] = useState(false);
  const [isPushChecked, setIsPushChecked] = useState(push);

  const closeModals = () => {
    setSettingNameActive(false);
    setSettingBirthdayActive(false)
    setSettingCityActive(false)
    setSettingPhoneActive(false)
    setSettingEmailActive(false)
    setSettingPasswordActive(false)
    setShowCityModal(false)
  }

  useEffect(() => {
    setIsPushChecked(push)
  }, [push])

  useLayoutEffect(() => {
    //getPersonalInfo().catch(err => logout())
    user.getPersonalInfo()
  }, []);

  const push1 = useSubscribe({publicKey: pushPublicKey})

  const onPushChange = () => {
    //alert('1')
    // Пытаемся включить пуши
    if (!isPushChecked) {
      //alert('2')
      if (Notification.permission === "denied") {
        //alert('3')
        alert("Оповещения отклонены, пожалуйста включите их в браузере")
        submitPersonalInfo({'push': false}).then(() => {
          setIsPushChecked(false)
        })
        return
      }
      push1.getSubscription().then(sub => {
        //alert('4')
        return apiClient.post(`/push/subscribe`, JSON.stringify(sub))
      }).then(() => {
        //alert('5')
        submitPersonalInfo({'push': true}).then(() => {
          setIsPushChecked(true)
        })
      })
    } else {
      //alert('6')
      submitPersonalInfo({'push': false}).then(() => {
        setIsPushChecked(false)
      })
    }
  };

  const fileField = useRef();

  const sendAvatar = () => {
    if (fileField.current.value.length) {
      if (fileField.current.files.length && fileField.current.files[0]) {
        const maxAllowedSize = 25 * 1024 * 1024;
        if (fileField.current.files[0].size > maxAllowedSize) {
          alert('Размер файла не должен превышать 25мб')
          fileField.current.value = ''
        }
      }

      updateUserAvatar(fileField.current).then((response) => {
        console.log(response)
        fileField.current.value = '';

        apiGetUserProfile().then((res) => {
          user.setAvatar(res.data.avatar)
        })
      })
    }
  }


  return (
    <>
      <LayoutWithTopBlock isTopBlockFixed={true}>
        {user && (
          <div className="main settings bg_2">
            <div className="sections container container_16">
              <section className="section">
                <div className="settings__avatar">
                  <img
                    src={avatar.length ? avatar : "./assets/images/avatar.svg"}
                    alt="avatar"/>
                  <div className="settings__add-photo">
                    <Icon name="camera"/>
                    <input
                      type="file"
                      ref={fileField}
                      onChange={sendAvatar}
                      name="avatar"
                      accept="image/*"/>
                  </div>
                </div>

                <div className="settings-list container container_16">
                  <div onClick={() => setSettingNameActive(true)} className="setting" data-popup="settingsName">
                    <div className="setting__info">
                      <div className="setting__name">ФИО</div>
                      <div className="setting__value" data-setting="user-name">
                        {`${lastName} ${firstName} ${secondName}`}
                      </div>
                    </div>
                    <span className="setting__arrow">
                      <Icon name="angle-right"/>
                    </span>
                  </div>

                  <div onClick={() => setSettingBirthdayActive(true)} className="setting" data-popup="settingsBirthday">
                    <div className="setting__info">
                      <div className="setting__name">Дата рождения</div>
                      <div className="setting__value" data-setting="birthday">{birthDate}</div>
                    </div>
                    <span className="setting__arrow">
                        <Icon name="angle-right"/>
                    </span>
                  </div>

                  <div onClick={() => setSettingCityActive(true)} className="setting" data-popup="settingsCity">
                    <div className="setting__info">
                      <div className="setting__name">Город по прописке</div>
                      <div className="setting__value" data-setting="city">{city}</div>
                    </div>
                    <span className="setting__arrow">
                      <Icon name="angle-right"/>
                    </span>
                  </div>

                  <div onClick={() => setSettingPhoneActive(true)} className="setting" data-popup="settingsPhone">
                    <div className="setting__info">
                      <div className="setting__name">Номер телефона</div>
                      <div className="setting__value"
                           data-setting="phone">{phone?.replace(/(\+7)(\d{3})(\d{3})(\d{2})(\d{2})/, "$1 $2 $3 $4$5")}</div>
                    </div>
                    <span className="setting__arrow">
                        <Icon name="angle-right"/>
                    </span>
                  </div>
                  {/*
                  <div onClick={() => setSettingEmailActive(true)} className="setting" data-popup="settingsEmail">
                    <div className="setting__info">
                      <div className="setting__name">e-mail</div>
                      <div className="setting__value" data-setting="email">{email}</div>
                    </div>
                    <span className="setting__arrow">
                        <Icon name="angle-right"/>
                    </span>
                  </div>
                  */}
                </div>
              </section>
              <section className="section">
                <div className="container">
                <div onClick={() => setShowCityModal(true)} className="setting" data-popup="settingsCity">
                  <div className="setting__info">
                    <div className="setting__name">Город по умолчанию</div>
                    <div className="setting__value" data-setting="city">{cityList.find((item) => item.id === parseInt(clinicCityId))?.text}</div>
                  </div>
                  <span className="setting__arrow">
                      <Icon name="angle-right"/>
                    </span>
                </div>
                </div>
              </section>
              <section className="section">
                <div className="settings-list container container_16">
                  <div className="setting" data-setting="push-notifications">
                    <div className="setting__info">Пуш-уведомления</div>
                    <div className="setting__switch">
                      <Switch checked={isPushChecked} onClick={onPushChange}/>
                    </div>
                  </div>
                </div>
              </section>
              <section className="section">
                <div className="settings-list container container_16">
                  <div onClick={() => setSettingPasswordActive(true)} className="setting" data-popup="settingsPassword">
                    <div className="setting__info">Изменить пароль</div>
                    <span className="setting__arrow"><Icon name="angle-right"/></span>
                  </div>
                  {/*
                  <div className="setting">
                    <div className="setting__info">Вход по Face или Touch ID</div>
                    <div className="setting__switch">
                      <Switch/>
                    </div>
                  </div>
                  */}
                </div>
              </section>
            </div>
          </div>
        )}
      </LayoutWithTopBlock>
      <ModalSettingsName active={settingNameActive} onClose={closeModals}/>
      <ModalSettingsBirthday active={settingBirthdayActive} onClose={closeModals}/>
      <ModalSettingsCity active={settingCityActive} city={city} onClose={closeModals}/>
      <ModalSettingsPhone active={settingPhoneActive} pin={pin} onClose={closeModals}/>
      <ModalSettingsEmail active={settingEmailActive} email={email} onClose={closeModals}/>
      <ModalSettingsPassword active={settingPasswordActive} code={pin} onClose={closeModals}/>
      <ModalCity active={showCityModal} onClose={closeModals}/>
    </>
  );
}

export default PageSettings;
