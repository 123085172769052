import React, {useState} from 'react';
import PropTypes from "prop-types";
import useClickOutside from "../utils/hooks/useClickOutside";
import cn from "classnames";
import Icon from "./Icon";
import ModalCity from "./modals/ModalCity";
import {useAppointmentStore} from "../store/appointmentStore";
import {useUserStore} from "../store/user-store";

const SelectCity = ({placeholder, className, onSelect}) => {
  const [showCityModal, setShowCityModal] = useState(false)
  const [clinicCityId] = useUserStore((state) => [state.clinicCityId]);
  const [cityList] = useAppointmentStore((state) => [state.cityList]);

  return (
    <>
      <div className={cn('select', className)}>
        <div
          className={cn('select__header', {'select__header--selected': clinicCityId})}
          onClick={() => setShowCityModal(!showCityModal)}
        >
        <span className="select__placeholder">
          {placeholder}
        </span>
          <span className="select__value">
          {clinicCityId ? cityList.find((item) => item.id === parseInt(clinicCityId))?.text : ' '}
        </span>
          <Icon name="drop-down" className="select__chevron"/>
        </div>
      </div>

      <ModalCity
        active={showCityModal}
        onChange={onSelect}
        onClose={() => setShowCityModal(false)}
      />
    </>
  );
};

SelectCity.propTypes = {
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
}

export default SelectCity;