import React, {useState, useEffect} from 'react';
import {apiGetAppointmentTimeList} from "../../../api/appointmentApi";
import Icon from "../../../components/Icon";
import { parse, format } from 'date-fns';
import { ru } from 'date-fns/locale';
import cn from "classnames";


const TimeBtn = ({time, currentTime, onClick}) => {
  return (
    <button
      className={cn('page-appointment__time', {'page-appointment__time--selected': time === currentTime})}
      onClick={() => onClick(time)}
    >
      {time}
    </button>
  )
}
function StepDiagnosticDate({toNextStep}) {
  const [list, setList] = useState([])
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [currentTimeList, setCurrentTimeList] = useState([]);
  const [currentDate, setCurrentDate] = useState('');
  const [currentTime, setCurrentTime] = useState('');

  useEffect(() => {
    apiGetAppointmentTimeList().then((res) => {
      setList(res)
      setCurrentDate(res[0].date)
      setDate(res[0].date)
      setCurrentTimeList(res[0].timeList)
    })
  }, [])

  const handleSelect = () => {
    const diagnosticsDate = new Date(`${date} ${time}`.replace(/(\d{2})\.(\d{2})\.(\d{4})/, '$3-$2-$1') + ':00');
    toNextStep(diagnosticsDate.toISOString())
  }

  const formatDate = (dateString) => {
    const date = parse(dateString, 'dd.MM.yyyy', new Date())
    const day = format(date, 'dd');
    const dayOfWeekFull = format(date, 'EEEE', { locale: ru })
    const weekDates = [
      {full: 'понедельник', abbr: 'Пн'},
      {full: 'вторник', abbr: 'Вт'},
      {full: 'среда', abbr: 'Ср'},
      {full: 'четверг', abbr: 'Чт'},
      {full: 'пятница', abbr: 'Пт'},
      {full: 'суббота', abbr: 'Сб'},
      {full: 'воскресенье', abbr: 'Вс'},
    ]
    const dayOfWeek = weekDates.find(day => day.full === dayOfWeekFull).abbr
    return `<span class="page-appointment__day-num">
            ${day}
              <span class="page-appointment__day-week">
                ${dayOfWeek}
              </span>
            </span>`
  }

  const handleSelectDate = (date) => {
    setCurrentDate(date)
    setCurrentTime('')
    setTime('')
    const t = list.find((day) => day.date === date)
    setCurrentTimeList(t.timeList)
  }

  const handleSelectTime = (time) => {
    setCurrentTime(time)
    setTime(time)
  }

  return (
    <div className="container">
      <div className="panel panel--top">
        <div className="page-appointment__top">
          <p className="page-appointment__title">Дата диагностики</p>
          <div className="page-appointment__date-list">
            {list.map((day) => (
              <button
                className={cn('page-appointment__date-btn', {'page-appointment__date-btn--selected': currentDate === day.date})}
                dangerouslySetInnerHTML={{ __html: formatDate(day.date) }}
                onClick={() => handleSelectDate(day.date)}
              />
            ))}
          </div>
          <p className="page-appointment__note">
            <Icon name="info" className="page-appointment__note-icon"/>
            Результаты диагностики действительны 30 дней. Учтите это при выборе даты
          </p>
        </div>
      </div>
      <div className="panel">
        <p className="page-appointment__title">Время диагностики</p>
        {currentTimeList.length > 0 && (
          <div className="page-appointment__time-list">
            {currentTimeList[0].length > 0 && (
              <>
                <p className="page-appointment__part-of-day">Утро</p>
                <div className="page-appointment__time-row">
                  {currentTimeList[0].map((time) => (
                    <TimeBtn time={time} currentTime={currentTime} onClick={handleSelectTime} />
                  ))}
                </div>
              </>
            )}

            {currentTimeList[1].length > 0 && (
              <>
                <p className="page-appointment__part-of-day">День</p>
                <div className="page-appointment__time-row">
                  {currentTimeList[1].map((time) => (
                    <TimeBtn time={time} currentTime={currentTime} onClick={handleSelectTime} />
                  ))}
                </div>
              </>
            )}

            {currentTimeList[2].length > 0 && (
              <>
                <p className="page-appointment__part-of-day">Вечер</p>
                <div className="page-appointment__time-row">
                  {currentTimeList[2].map((time) => (
                    <TimeBtn time={time} currentTime={currentTime} onClick={handleSelectTime} />
                  ))}
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <button className="button page-appointment__bottom-button" type="button" disabled={!date || !time} onClick={handleSelect}>Продолжить</button>
    </div>
  );
}

export default StepDiagnosticDate;