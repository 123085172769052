import PropTypes from "prop-types";
import "./service.scss"

const Service = ({id, name, description, price, onSelect}) => {
  return (
    <div className="service panel">
      <h2 className="service__name">{name}</h2>
      <p className="service__description">{description}</p>
      <div className="service__bottom">
        <p className="service__price">{parseInt(price).toLocaleString('ru-RU')} ₽</p>
        <button
          type="button"
          className="button button_h-52 service__button"
          onClick={()=>onSelect({id, name, price})}
        >
          Выбрать
        </button>
      </div>
    </div>
  )
}

Service.propTypes ={
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default Service