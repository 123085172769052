import apiClient from './axiosConfig';

// Получение городов для записи
export const apiGetCityList = async () => {
  //const response = await apiClient.get(`/get-city-list`, {cache: {interpretHeader:false}});
  return [
    {id: 1, text: 'Москва'},
    {id: 2, text: 'Санкт-Петербург'},
    {id: 3, text: 'Новосибирск'},
    {id: 4, text: 'Воронеж'},
    {id: 5, text: 'Омск'},
    {id: 6, text: 'Барнаул'},
    {id: 7, text: 'Пенза'},
    {id: 8, text: 'Красноярск'},
    {id: 9, text: 'Ижевск'},
    {id: 10, text: 'Ростов-на-Дону'},
    {id: 11, text: 'Уфа'},
    {id: 12, text: 'Самара'},
    {id: 13, text: 'Оренбург'},
    {id: 14, text: 'Казань'},
    {id: 15, text: 'Тольятти'},
  ];
};

export const apiSetSelectedCityId = async (cityId) => {
  //const response = await apiClient.get(`/set-selected-city`, {cache: {interpretHeader:false}});
  localStorage.setItem('selected-city', JSON.stringify(cityId)) // TODO: костыль
  return true
}

export const apiGetClinics = async (cityId) => {
  console.warn(`version 2: endpoint - /get-clinics, data - {clinicCityId: ${cityId}}`)
  //const response = await apiClient.get(`/get-clinics`, {cache: {interpretHeader:false}});
  return [
    {
      id: '1',
      rating: '3.5',
      reviewsCounter: '1200',
      name: 'Смотри Клиника',
      addr: 'Толстого 1',
      metro: '',
      workTime: {
        summary: 'пн-пт 08:30 – 17:00; cб 9:00 – 16:00',
        detail: [
          'пн - 08:30 – 17:00',
          'вт - 08:30 – 17:00',
          'ср - 08:30 – 17:00',
          'чт - 08:30 – 17:00',
          'пт - 08:30 – 17:00',
          'сб - 9:00 – 16:00',
          'вс - выходной',
        ]
      },
      // ограничения
      photoList: [
        {
          thumbSrc: 'assets/images/clinics/image1_thumb.jpg',
          imageSrc: 'assets/images/clinics/image1.jpg',
        },
        {
          thumbSrc: 'assets/images/clinics/image2_thumb.jpg',
          imageSrc: 'assets/images/clinics/image2.jpg',
        },
        {
          thumbSrc: 'assets/images/clinics/image3_thumb.jpg',
          imageSrc: 'assets/images/clinics/image3.jpg',
        },
        {
          thumbSrc: 'assets/images/clinics/image4_thumb.jpg',
          imageSrc: 'assets/images/clinics/image4.jpg',
        },
        {
          thumbSrc: 'assets/images/clinics/image5_thumb.jpg',
          imageSrc: 'assets/images/clinics/image5.jpg',
        },
      ],
      serviceList: [
        {
          id: '1',
          name: 'Прием',
          amount: '1200',
        },
        {
          id: '2',
          name: 'Коррекция',
          amount: '2000',
        },
      ]
    },
    {
      id: '2',
      rating: '3.5',
      reviewsCounter: '1200',
      name: 'Кузляр',
      addr: 'Достоевского 77',
      metro: 'Козья Слобода',
      workTime: {
        summary: '12:00-19:00 ежедневно',
        detail: []
      },
      photoList: [
        {
          thumbSrc: 'assets/images/clinics/image1_thumb.jpg',
          imageSrc: 'assets/images/clinics/image1.jpg',
        },
        {
          thumbSrc: 'assets/images/clinics/image2_thumb.jpg',
          imageSrc: 'assets/images/clinics/image2.jpg',
        },
        {
          thumbSrc: 'assets/images/clinics/image3_thumb.jpg',
          imageSrc: 'assets/images/clinics/image3.jpg',
        },
      ],
      serviceList: [
        {
          id: '1',
          name: 'Прием',
          amount: '1900',
        },
        {
          id: '2',
          name: 'Коррекция 1',
          amount: '5000',
        },
        {
          id: '3',
          name: 'Коррекция 2',
          amount: '7000',
        },
      ]
    }
  ]
}

export const apiGetCurrentStageData = async () => {
  //const response = await apiClient.get(`/get-current-stage`, {cache: {interpretHeader:false}});
  return []
}

export const apiGetStages = async () => {
  //const response = await apiClient.get(`/get-stages`, {cache: {interpretHeader:false}});
  return [
    {
      id: 1,
      name: 'Запись в клинику',
      description: '',
      isCompleted: true
    },
    {
      id: 2,
      name: 'Диагностика',
      description: 'Комплексная проверка зрения у врача-офтальмолога помогает определить возможность проведения лазерного лечения и настроить оборудование.',
      isCompleted: false
    },
    {
      id: 3,
      name: 'Коррекция',
      description: 'За несколько минут опытный хирург-офтальмолог восстановит ваше зрение.',
      isCompleted: false
    },
    {
      id: 4,
      name: 'Плановый осмотр',
      description: 'После лазерной коррекции вас запишут на контрольный осмотр',
      isCompleted: false
    },
  ]
}
export const apiCurrentStage = async () => {
  //const response = await apiClient.get(`/get-current-stage`, {cache: {interpretHeader:false}});
  return {
    number: 2,
    name: "Диагностика",
    description: 'Комплексная проверка зрения у врача-офтальмолога помогает определить возможность проведения лазерного лечения и настроить оборудование.',
    isCompleted: false,
    clinic: {
      city: 'Москва',
      addr: 'Воскресенская 1 оф 2',
      link: [12.11111, 12.11111] // map link
    },
    date: '25 марта в 12:20',
    method: 'Lasik',
    payment: {
      isPaid: false,
      amount: 2000,
      discountList: [
        {
          name: 'Промокод',
          amount: 500
        },
        {
          name: 'Акция',
          amount: 500
        },
      ],
      total: 1000,
      paymentLink: 'https://sber.ru'
    }
    //materials: 322 // id
  }
}

export const apiGetServices = async (clinicId) => {
  console.warn(`version 2: endpoint - /get-services, data - {clinicId: ${clinicId}}`)
  //const response = await apiClient.get(`/get-services`, {cache: {interpretHeader:false}});
  return [
    {
      id: '1',
      name: 'Lasik',
      description: 'На роговице формируют лоскут, обрабатывают лазером под лоскутом и возвращают лоскут на место. При Lasik лоскут отделяют тонким лезвием, при Femto Lasik — фемтосекундным лазером',
      price: '13500',
    },
    {
      id: '2',
      name: 'Femto Lasik',
      description: 'При помощи лазера формируют на роговице лоскут, обрабатывают под ним лазером и возвращают лоскут на место',
      price: '38000',
    },
    {
      id: '3',
      name: 'Super Femto Lasik',
      description: 'Тот же Femto Lasik, но все манипуляции проводятся только на основе данных пациента и выполняются исключительно при помощи лазеров. Операция показана пациентам с очень тонкой роговицей или очень высокой степенью близорукости.',
      price: '46500',
    },
  ]
}

export const apiServiceDateList = async (clinicId, methodId) => {
  console.warn(`version 2: endpoint - /get-service-date-list, data - {clinicId: ${clinicId}, methodId: ${methodId}}`)
  //const response = await apiClient.get(`/get-service-date-list`, {cache: {interpretHeader:false}});
  return [
    '07.12.2024',
    '09.12.2024',
    '11.12.2024',
    '12.12.2024',
    '14.12.2024',
    '15.12.2024',
    '16.12.2024',
    '18.12.2024',
    '20.12.2024',
    '02.01.2025',
    '05.01.2025',
    '07.01.2025',
    '09.01.2025',
    '20.01.2025',
  ]
}

export const apiGetAppointmentTimeList = async (date) => {
  //const response = await apiClient.get(`/get-appointment-time`, {cache: {interpretHeader:false}});
  return [
    {
      date: '02.12.2024',
      timeList: [ // array
        [
          '08:20',
          '08:40',
          '08:50',
          '09:10',
          '09:20',
          '09:40',
          '09:50',
          '11:40',
          '11:50',
        ],
        [
          '12:20',
          '12:40',
          '12:50',
          '13:20',
          '13:40',
          '13:50',
          '14:40',
          '14:50',
          '15:10',
          '15:20',
          '15:40',
          '15:50',
          '16:20',
          '16:40',
          '16:50',
          '17:10',
          '17:20',
          '17:40',
          '17:50',
        ],
        [
          '18:10',
        ]
      ]
    },
    {
      date: '01.12.2024',
      timeList: [
        [
          '11:40',
          '11:50',
        ],
        [
          '12:20',
          '12:40',
          '17:50',
        ],
        [
          '18:20',
        ]
      ]
    },
    {
      date: '30.12.2024',
      timeList: [
        [
          '08:20',
          '08:40',
          '11:50',
        ],
        [
          '12:20',
          '12:40',
          '12:50',
          '13:20',
          '16:50',
          '17:10',
          '17:20',
          '17:40',
          '17:50',
        ],
        [
          '18:10',
          '18:20',
        ]
      ]
    }
  ]
}
export const api = async () => {
  //const response = await apiClient.get(`/get-city-list`, {cache: {interpretHeader:false}});
  return
}