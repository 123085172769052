import PropTypes from "prop-types";
import Icon from "../Icon";

import "./clinic.scss"
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import {Image} from "antd";
import {useState} from "react";
import cn from "classnames";

const Clinic = ({photoList, rating, reviewsCounter, id, name, addr, metro, workTime, serviceList, handleSelect}) => {
  const [showDetailWorkTime, setShowDetailWorkTime] = useState(false)

  return (
    <div className="clinic panel">
      {photoList.length > 0 && (
        <Swiper className="clinic__gallery"
          spaceBetween={4}
          slidesPerView={4.2}
          //onSlideChange={() => console.log('slide change')}
          //onSwiper={(swiper) => console.log(swiper)}
        >
          {photoList.map((item, index) => (
            <SwiperSlide key={index} className="clinic__gallery-item">
              <Image className="clinic__gallery-thumb"
                preview={{
                  mask: false,
                  toolbarRender: () => false,
                  destroyOnClose: true,
                  movable: false
                }}
                src={item.imageSrc}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <div className="clinic__rate">
        <p className="clinic__rating">{rating}</p>
        <p className="clinic__reviews">{reviewsCounter} отзывов</p>
      </div>
      <h2 className="clinic__name">{name}</h2>
      <div className="clinic__data">
        <p className="clinic__addr">{addr}</p>
        {metro && (
          <p className="clinic__metro">
            <Icon name="metro" className="clinic__metro-icon"/>
            {metro}
          </p>
        )}
        <div className="clinic__work-time-box">
          <div className="clinic__work-time-title" onClick={() => setShowDetailWorkTime(!showDetailWorkTime)}>
            <p className="clinic__work-time-summary">
              {workTime.summary}
              {workTime.detail.length >0 && (
                <Icon name="drop-down" className={cn("clinic__work-time-icon",{"clinic__work-time-icon--active":showDetailWorkTime})}/>
              )}
            </p>
          </div>
        </div>
        {workTime.detail.length > 0 && showDetailWorkTime && (
          <ul className="clinic__work-time-list">
            {workTime.detail.map(row=> <li className="clinic__work-time-row">{row}</li>)}
          </ul>
        )}
      </div>

      <table className="clinic__services">
        <tbody>
          {serviceList.map((item, index) => (
            <tr className="clinic__service">
              <td className="clinic__service-name">{item.name}</td>
              <td className="clinic__service-amount">{parseInt(item.amount).toLocaleString('ru-RU')} ₽</td>
            </tr>
          ))}
        </tbody>
      </table>

      <button className="button" type="button" onClick={() => handleSelect({id, name, addr})}>Выбрать клинику</button>
    </div>
  )
}

Clinic.propTypes = {
  photoList: PropTypes.arrayOf(
    PropTypes.exact({
      imageSrc: PropTypes.string.isRequired,
      thumbSrc: PropTypes.string.isRequired
    })
  ),
  rating: PropTypes.number.isRequired,
  reviewsCounter: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  addr: PropTypes.string.isRequired,
  metro: PropTypes.string,
  workTime: PropTypes.string.isRequired,
  serviceList: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired
    })
  )
}

export default Clinic