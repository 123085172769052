import React, {useState, useEffect} from 'react';
import {apiGetServices} from "../../../api/appointmentApi";
import Icon from "../../../components/Icon";
import Service from "../../Service/Service";
import ModalPrepare from "../../../components/modals/ModalPrepare";

function StepServiceSelect({toNextStep}) {
  const [list, setList] = useState([])
  const [service, setService] = useState({id:'', name:''})
  const [showPrepareModal, setShowPrepareModal] = useState(false)

  const handleServiceSelect = (service) => {
    setService(service)
    setShowPrepareModal(true)
  }

  useEffect(() => {
    apiGetServices(1).then((res) => setList(res))
  }, [])

  const onAgree = () => {
    setShowPrepareModal(false)
    toNextStep(service)
  }

  return (
    <div className="container">
      <div className="panel panel--top">
        <div className="page-appointment__top">
          <p className="page-appointment__title">Метод коррекции</p>
          <p className="page-appointment__description">Цены указаны за оба глаза и не зависят от степени близорукости и
            астигматизма</p>
          <p className="page-appointment__note">
            <Icon name="info" className="page-appointment__note-icon"/>
            Окончательное решение о методе коррекции принимается после диагностики
          </p>
        </div>
      </div>
      {list && list.length > 0 && list.map((item) => (
        <Service
          {...item}
          key={item.index}
          onSelect={handleServiceSelect}
        />
      ))}
      <ModalPrepare
        active={showPrepareModal}
        onClose={onAgree}
        showAgreement={true}
      />
    </div>
  );
}

export default StepServiceSelect;