import {motion} from "framer-motion";
import Icon from "../Icon";
import {useState} from "react";
import {useAppointmentStore} from "../../store/appointmentStore";
import Radio from "../Radio";
import {useUserStore} from "../../store/user-store";
import {preventScroll} from "../../utils/preventScroll";

const ModalCity = ({active, onChange, onClose}) => {
  const [cityList] = useAppointmentStore((state) => [state.cityList])
  const [clinicCityId, submitPersonalInfo] = useUserStore((state) => [state.clinicCityId, state.submitPersonalInfo])
  const [cityId, setCityId] = useState()
  const show = {
    opacity: 1,
    display: "block",
  };

  const hide = {
    opacity: 0,
    transitionEnd: {
      display: "none"
    }
  };

  const onSubmit = () => {
    onChange(cityId)
    submitPersonalInfo({clinicCityId: cityId})
    onClose()
  }

  const onCitySelect = (id) => {
    setCityId(id)
  }

  return (
    <motion.div
      animate={active ? show : hide}
      className={`modal modal_type-3 modal-city modal-w-inputs ${active ? 'modal--show' : ''}`}
      onAnimationComplete={() => preventScroll(active)}
    >
      <div className="modal__inner">
        <div className="modal__wrap">
          <div onClick={onClose} className="modal__close modal-close">
            <Icon name="close"/>
          </div>
          <div className="modal__content">
            <h2 className="h2 modal-city__title">Выберите город</h2>
            <div className="modal-city__list">
              {cityList.map((item) => (
                <Radio
                  onCheck={onCitySelect}
                  name="city"
                  label={item.text}
                  value={item.id}
                  checked={parseInt(clinicCityId) === parseInt(item.id)}
                />
              ))}
            </div>
            <div className="modal-city__bottom-box">
              <button
                type="button"
                className="button button_h-52"
                onClick={onSubmit}
              >
                Сохранить изменения
              </button>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}
export default ModalCity